import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../client/axios.js';
import { INTEGRATION_LIST } from '../utils/index.js';

function IntegrationModal({ isOpen, onClose, integration, onConnect }) {
  const [formData, setFormData] = useState({});

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await onConnect(integration, formData);
    onClose();
  };

  useEffect(() => {
    if (integration) {
      const initialFormData = integration.requiredFields.reduce(
        (acc, field) => {
          acc[field.name] = field.value || '';
          return acc;
        },
        {}
      );
      setFormData(initialFormData);
    }
    return () => {
      setFormData({});
    };
  }, [integration]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-xl shadow-2xl max-w-md w-full mx-4 transform transition-all duration-300 ease-in-out">
        <div className="flex items-center mb-6">
          <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mr-4">
            <img
              src={integration.logo}
              alt={`${integration.name} logo`}
              className="w-12 h-12 object-contain"
            />
          </div>
          <h2 className="text-2xl font-bold text-gray-800">
            {integration.name}
          </h2>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          {integration.requiredFields.map((field) => (
            <div key={field.name}>
              <label
                htmlFor={field.name}
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                {field.displayName}
              </label>
              <input
                id={field.name}
                name={field.name}
                type="text"
                value={
                  integration.isConnected
                    ? integration.shopName
                    : formData[field.name] || ''
                }
                onChange={handleInputChange}
                placeholder={`Enter ${field.displayName}`}
                className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 ease-in-out"
                required
                disabled={integration.isConnected}
              />
            </div>
          ))}
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:text-gray-800 border border-gray-300 rounded-md hover:bg-gray-50 transition duration-200 ease-in-out"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200 ease-in-out ${integration.isConnected ? 'cursor-not-allowed' : ''}`}
              disabled={integration.isConnected}
              aria-label="Connect"
            >
              Connect
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

function IntegrationCard({ integration, isConnected, onConnect }) {
  return (
    <div className="flex-1 min-w-[200px] rounded-lg shadow-lg p-4 flex flex-col items-center bg-white">
      <img
        src={integration.logo}
        alt={`${integration.name} logo`}
        className="w-16 h-16 mb-2 object-contain"
      />
      <span className="text-lg font-semibold mb-2">{integration.name}</span>
      <button
        className={`px-4 py-2 rounded ${isConnected ? 'bg-green-500 hover:bg-green-600' : 'bg-blue-500 hover:bg-blue-600'} text-white`}
        onClick={onConnect}
      >
        {isConnected ? 'Connected' : 'Connect'}
      </button>
    </div>
  );
}

function Integrations() {
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [integrationsList, setIntegrationsList] = useState([]);

  useEffect(() => {
    fetchIntegrations();
  }, []);

  const fetchIntegrations = async () => {
    try {
      const integrations = await axiosInstance.get('/integrations/account');
      // const integrations = await Promise.all(
      //   INTEGRATION_LIST.map(async (integration) => {
      //     const response = await axios.get(`/api/${integration.name}/check`, {
      //       params: {
      //         accountId: user.accountId,
      //       },
      //     });
      //     if (response.data.isConnected) {
      //       return {
      //         ...integration,
      //         isConnected: response.data.isConnected,
      //         shopName: response.data.integration.authMetaData.shop,
      //       };
      //     }
      //     return { ...integration, isConnected: false, value: '' };
      //   })
      // );
      const integrationsList = INTEGRATION_LIST.map((integration) => {
        const isConnected = integrations.data.some(
          (connectedIntegration) =>
            connectedIntegration.name === integration.name
        );
        return {
          ...integration,
          isConnected,
          shopName: integrations.data.find(
            (connectedIntegration) =>
              connectedIntegration.name === integration.name
          )?.shopName,
        };
      });
      setIntegrationsList(integrationsList);
    } catch (error) {
      console.error('Error fetching integrations:', error);
    }
  };

  const openModal = (integration) => {
    setSelectedIntegration(integration);
  };

  const closeModal = () => {
    setSelectedIntegration(null);
  };

  const handleConnect = async (integration, formData) => {
    try {
      // fetch from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      const { accountId } = user;
      // For Shopify, we redirect to the auth URL
      const response = await axios.get(`/api/${integration.name}/install`, {
        params: { shop: formData.shop, accountId },
      });
      if (response.data.success) {
        window.open(response.data.authUrl, '_blank');
      }
    } catch (error) {
      console.error('Error connecting integration:', error);
    }
  };

  return (
    <>
      <div className="p-4">
        <div className="flex flex-wrap gap-4">
          {integrationsList.map((integration) => (
            <IntegrationCard
              key={integration.name}
              integration={integration}
              isConnected={integration.isConnected}
              onConnect={() => openModal(integration)}
            />
          ))}
        </div>
      </div>
      {selectedIntegration && (
        <IntegrationModal
          isOpen={!!selectedIntegration}
          onClose={closeModal}
          integration={selectedIntegration}
          onConnect={handleConnect}
        />
      )}
    </>
  );
}

export default Integrations;
