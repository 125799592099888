import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  useSearchParams,
} from 'react-router-dom';
import Login from './login.js';
import Signup from './signup.js';
import Home from './home.js';
import './index.css';
import Header from './components/header.js';
import Sidebar from './components/sidebar.js';
import Integrations from './pages/integrations.js';
import Gorgias from './gorgias-connect.jsx';
import axiosInstance from './client/axios.js';
// import { verifyJwtToken } from "./helpers/jwt_verify.js";

const Layout = () => {
  const [searchParams] = useSearchParams();
  const ssoToken = searchParams.get('ssoToken');
  console.log(ssoToken, '---ssoToken');
  useEffect(() => {
    console.log('Layout component mounted', localStorage.getItem('token'));
    const ssoToken = new URLSearchParams(window.location.search).get(
      'ssoToken'
    );
    console.log(ssoToken, '---ssoToken');
    if (!localStorage.getItem('token') && !ssoToken) {
      const redirectURL = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      console.log(redirectURL, '---redirectURL');
      window.location.href = `${process.env.REACT_APP_DEV_CLIENT_URL}/crm-onboarding/login?redirectURL=${redirectURL}`;
    }
    if (ssoToken) {
      console.log(window.location, '---window.location.pathname');
      const redirectURL = `${window.location.protocol}//${window.location.host}${window.location.pathname || ''}`;
      (async () => {
        const response = await axiosInstance.get(
          `/account/verify-sso-token?ssoToken=${ssoToken}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer l1Q7zkOL59cRqWBkQ12ZiGVW2DBL',
            },
          }
        );
        console.log(response, '---response');
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        window.location.href = redirectURL;
      })();
    }

    console.log(window.location.pathname, '---window.location.pathname 00');

    if (
      window.location.pathname !== '/crm-onboarding/login' &&
      localStorage.getItem('token')
    ) {
      (async () => {
        try {
          const response = await axiosInstance.get(`/account/check`, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          console.log(response, '---response');
          // if (!response.data.isAuthenticated) {
          //   localStorage.removeItem('token');
          //   localStorage.removeItem('user');
          //   navigate('/login');
          // }
        } catch (error) {
          console.log(error, '---error from check');
          if (error.response && error.response.status === 401) {
            console.log('Unauthorized: Clearing user data');
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            window.location.href = '/crm-onboarding/login';
          } else {
            console.log('Error occurred, but not clearing user data:', error);
          }
        }
      })();
    }

    return () => {
      console.log('Layout component unmounted');
    };
  }, [window.location.pathname]);
  console.log(window.location.pathname, '---window.location.pathname');

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex flex-col flex-1">
        <Header />
        <main className="flex-1 overflow-y-auto p-6 bg-gray-100">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router basename="/shopify">
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/gorgias" element={<Gorgias />} />
        <Route path="/integrations" element={<Integrations />} />
        <Route path="*" element={<div>Not Found</div>} />
      </Route>
    </Routes>
  </Router>
);
