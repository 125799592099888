import React from 'react';
import AgentHeadphone from './assets/icons/agent-headphone.svg';
import ChannelConnectIcon from './assets/icons/channel-connect.svg';
import InsightsIcon from './assets/icons/actionable-insight.svg';

const HomePage = () => {
  const handleSetup = async (platform) => {
    console.log('setup', platform); // Line 6
  };

  const user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null;

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      <div className="flex-1 p-8">
        <h2 className="text-2xl font-semibold mb-8">
          Welcome back, {user ? user.name : ''}!
        </h2>

        <div className="bg-white p-6 rounded-lg shadow-md mb-8">
          <h3 className="text-xl font-semibold mb-4">
            Get the most out of your kim.cc dashboard
          </h3>
          <ul className="space-y-4">
            {[
              {
                icon: ChannelConnectIcon,
                title: 'Connect your channels in Kim CRM',
              },
              {
                icon: InsightsIcon,
                title: 'Get Actionable Insights about your CX',
              },
              {
                icon: AgentHeadphone,
                title: 'Hire a Remote Agent for Part-time or Full-time Support',
              },
              {
                icon: AgentHeadphone,
                title: 'AI Agent/Copilot (copy to be written)',
              },
            ].map((item, index) => (
              <li
                key={index}
                className="flex items-center justify-between p-4 border border-disableColor rounded-lg"
              >
                <span className="flex items-center">
                  <img
                    src={item.icon}
                    alt={item.title}
                    className="mr-2 w-5 h-5"
                  />
                  {item.title}
                </span>
                <span className="text-blue-600">↗</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-4">Setup Integrations</h3>
          <div className="flex space-x-4">
            <button
              onClick={() => handleSetup('loop')}
              className="px-4 py-2 border rounded-lg"
            >
              loop
            </button>
            <button
              onClick={() => handleSetup('recharge')}
              className="px-4 py-2 border rounded-lg"
            >
              recharge
            </button>
            {/* Add more integration buttons as needed */}
          </div>
        </div>
      </div>

      {/* Footer remains unchanged */}
    </div>
  );
};

export default HomePage;
