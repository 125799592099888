import React, { useState } from 'react';
import KimLogo from '../assets/kim-logo-transparent.png';
import colorBlocks from '../assets/icons/color-blocks.svg';
import axiosInstance from '../client/axios.js';

function Header() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedCRM, setSelectedCRM] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [gorgiasAccountName, setGorgiasAccountName] = useState(null);

  const handleCRMSelection = (crm) => {
    setSelectedCRM(crm);
  };

  const handleContinue = () => {
    console.log('Selected CRM:', selectedCRM);
    if (selectedCRM === 'Gorgias') {
      window.open(
        `${process.env.PULSE_FRONTEND_URL}?crmType=gorgias&accountName=${gorgiasAccountName}`,
        '_blank'
      );
    } else if (selectedCRM === 'Kim CRM') {
      window.open(`${process.env.PULSE_FRONTEND_URL}?crmType=kim`, '_blank');
    }
    closeModal();
    // Add your logic here to handle the selected CRM
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const hasGorgiasIntegrated = async () => {
    const response = await axiosInstance.get('/integrations/account');
    const integration = response.data;
    let isIntegrated = false;
    integration.forEach((integration) => {
      if (integration.name === 'gorgias') {
        setGorgiasAccountName(integration.shopName);
        isIntegrated = true;
      }
    });
    return isIntegrated;
  };

  const handleOptionChange = async (platform) => {
    console.log(platform);
    setDropdownOpen(false);
    if (platform === 'Inbox') {
      await getSSOLink();
    } else if (platform === 'Copilot') {
      console.log('Copilot');
      window.open(`${process.env.COPILOT_FRONTEND_URL}`, '_blank');
    } else if (platform === 'Pulse') {
      setIsLoading(true);
      if (await hasGorgiasIntegrated()) {
        console.log('Gorgias integrated');
        openModal();
        setIsLoading(false);
      } else {
        console.log('Gorgias not integrated');
        setIsLoading(false);
        window.open(`${process.env.PULSE_FRONTEND_URL}?crmType=kim`, '_blank');
      }
    }
  };

  const getSSOLink = async () => {
    const response = await axiosInstance.get('/account/sso');
    console.log(response.data, '==');
    const url = new URL(response.data.ssoLink.url);
    const params = new URLSearchParams(url.search);
    const email = params.get('email');
    const sso_auth_token = params.get('sso_auth_token');
    console.log(
      `${response.data.subDomain}/app/login?email=${email}&sso_auth_token=${sso_auth_token}`
    );
    window.open(
      `https://${response.data.subDomain}.kim.cc/app/login?email=${email}&sso_auth_token=${sso_auth_token}`,
      '_blank'
    );
  };

  return (
    <header className="bg-gray-100 shadow-sm py-5 px-4 flex justify-between items-center">
      {isLoading && (
        <div className="fixed inset-0 bg-white bg-opacity-75 flex justify-center items-center z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      )}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Select CRM</h2>
            <div className="space-y-2 mb-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="crm"
                  value="Gorgias"
                  onChange={() => handleCRMSelection('Gorgias')}
                  className="mr-2"
                />
                Gorgias
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="crm"
                  value="Kim CRM"
                  onChange={() => handleCRMSelection('Kim CRM')}
                  className="mr-2"
                />
                Kim CRM
              </label>
            </div>
            <div className="flex justify-end space-x-2">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleContinue}
                disabled={!selectedCRM}
                className={`px-4 py-2 rounded ${
                  selectedCRM
                    ? 'bg-blue-500 text-white hover:bg-blue-600'
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="text-xl font-bold">
        <img src={KimLogo} alt="Kim Logo" className="w-1/4" />
      </div>
      <div className="relative flex items-center space-x-2">
        <button
          onClick={toggleDropdown}
          className="flex items-center space-x-2 px-5 py-2 z-1 rounded-3xl bg-[#EBEFFF]"
        >
          <img src={colorBlocks} alt="color blocks" />
          <span className="text-base font-medium">Apps</span>
        </button>
        {dropdownOpen && (
          <div className="absolute right-2 top-[100%] bg-white shadow-lg rounded-lg">
            <ul className="text-gray-200 text-lg">
              <li className="px-7 py-2 hover:bg-gray-100 cursor-pointer flex items-center">
                <label
                  htmlFor="pulseOption"
                  className="ml-2 cursor-pointer"
                  onClick={() => handleOptionChange('Pulse')}
                >
                  Pulse
                </label>
              </li>
              <li className="px-7 py-2 hover:bg-gray-100 cursor-pointer flex items-center">
                <label
                  htmlFor="ticketingOption"
                  className="ml-2 cursor-pointer"
                  onClick={() => handleOptionChange('Inbox')}
                >
                  Inbox
                </label>
              </li>
              <li className="px-7 py-2 hover:bg-gray-100 cursor-pointer flex items-center">
                <label
                  htmlFor="hireOption"
                  className="ml-2 cursor-pointer"
                  onClick={() => handleOptionChange('Copilot')}
                >
                  Copilot
                </label>
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
