import React, { useState, useEffect } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import HelpdeskFrame from './assets/helpdesk_frame.webp';
import HelpdeskCover from './assets/helpdesk_cover2.webp';
import KimLogo from './assets/kim-logo-transparent.png';
import axiosInstance from './client/axios.js';

function Signup() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const redirectPlatform = searchParams.get('redirectPlatform');

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    companyName: '',
    subDomain: '',
    email: '',
    password: '',
    fullName: '',
  });
  const [passwordCriteria, setPasswordCriteria] = useState({
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
    length: false,
  });
  const [showPasswordCriteria, setShowPasswordCriteria] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [loadedImage, setLoadedImage] = useState(HelpdeskCover);

  useEffect(() => {
    const subDomainName = formData.companyName
      .split(' ')
      .join('-')
      .toLowerCase();
    setFormData((prev) => ({ ...prev, subDomain: subDomainName }));
  }, [formData.companyName]);

  useEffect(() => {
    const img = new Image();
    img.src = HelpdeskFrame;
    img.onload = () => {
      if (img.complete) {
        setLoadedImage(HelpdeskFrame);
      }
    }
  }, []);

  const validatePassword = (password) => {
    setShowPasswordCriteria(true);
    setPasswordCriteria({
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      length: password.length >= 12,
    });
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === 'password') {
      validatePassword(value);
    }
    if (id === 'companyName' && /[!@#$%^&*(),.?":{}|<>-]/.test(value)) {
      setErrorMessage('Company name cannot contain special characters');
      return;
    }
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleNextStep = (event) => {
    event.preventDefault();
    const allValid = Object.values(passwordCriteria).every(Boolean);
    if (!allValid) {
      setErrorMessage('Please meet all password criteria.');
      return;
    }
    setStep(2);
    setErrorMessage('');
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    if (!formData.subDomain) {
      setErrorMessage('Please enter a subdomain.');
      return;
    }
    setIsSigningUp(true);
    setErrorMessage('');

    try {
      const response = await axiosInstance.post(
        `/account${location.search}`,
        formData,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (response.data?.shopifyRedirectURL) {
        window.open(response.data.shopifyRedirectURL, '_self');
        return;
      }

      if (redirectPlatform === 'crm') {
        window.open(response.data.loginURL, '_self');
      } else {
        alert(
          'Invalid redirect platform please contact at support@kim.cc to get access to the platform'
        );
      }
    } catch (error) {
      console.error('Error:', error);
      const errorMessage =
        error?.response?.data && error?.response?.data?.message
          ? error?.response?.data?.message
          : 'Failed to create account. Please try again.';
      setErrorMessage(errorMessage);
      setIsSigningUp(false);
    }
  };

  const renderCriteriaIcon = (isValid) => {
    return isValid ? (
      <span className="text-green-500 mr-2">✓</span>
    ) : (
      <span className="text-red-500 mr-2">✗</span>
    );
  };

  return (
    <div
      style={{ 
        backgroundImage: `url(${loadedImage})`,
        backgroundSize: 'cover',
        backgroundPositionY: 'top',
      }}
      className="flex justify-center items-center min-h-screen bg-center p-4 relative backdrop-blur-[12px] bg-no-repeat"
    >
      {/* Dark overlay */}
      <div className="absolute inset-0 bg-black/70"></div>
      
      <div className="form px-4 py-12 sm:p-12 rounded-md w-full max-w-md bg-white shadow-xl relative z-10">
        <img src={KimLogo} alt="Kim Logo" className="w-1/4 sm:w-1/4  mb-4" />
        <div className="flex items-center gap my-8">
          {step === 2 && (
            <button
              type="button"
              onClick={() => setStep(1)}
              className="flex items-center text-[#272727] hover:text-gray-800 font-bold rounded-lg transition-colors duration-200 hover:bg-gray-100"
            >
              <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
          )}
          <h2
            className="text-xl sm:text-2xl font-semibold text-left"
            style={{ color: '#272727' }}
          >
            {step === 1 ? 'Create Your Account' : 'Create your workspace'}
          </h2>
        </div>

        {step === 1 ? (
          <form className="flex flex-col space-y-4" onSubmit={handleNextStep}>
            <input
              type="text"
              id="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              placeholder="Full Name"
              className="py-2 px-4 border border-[#d9d9d9] rounded-lg placeholder-[#948F8F]"
            />
            <input
              type="text"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Your Email"
              className="py-2 px-4 border border-[#d9d9d9] rounded-lg placeholder-[#948F8F]"
              autoComplete="off"
            />
            <input
              type="password"
              id="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Password"
              className="py-2 px-4 border border-[#d9d9d9] rounded-lg placeholder-[#948F8F]"
              autoComplete="off"
            />
            {showPasswordCriteria && (
              <div className="text-xs sm:text-sm">
                <ul className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                  <li
                    className={
                      passwordCriteria.uppercase
                        ? 'text-green-500'
                        : 'text-red-500'
                    }
                  >
                    {renderCriteriaIcon(passwordCriteria.uppercase)}
                    An uppercase letter
                  </li>
                  <li
                    className={
                      passwordCriteria.lowercase
                        ? 'text-green-500'
                        : 'text-red-500'
                    }
                  >
                    {renderCriteriaIcon(passwordCriteria.lowercase)}A lowercase letter
                  </li>
                  <li
                    className={
                      passwordCriteria.number ? 'text-green-500' : 'text-red-500'
                    }
                  >
                    {renderCriteriaIcon(passwordCriteria.number)}A number
                  </li>
                  <li
                    className={
                      passwordCriteria.special ? 'text-green-500' : 'text-red-500'
                    }
                  >
                    {renderCriteriaIcon(passwordCriteria.special)}A special character
                  </li>
                  <li
                    className={
                      passwordCriteria.length ? 'text-green-500' : 'text-red-500'
                    }
                  >
                    {renderCriteriaIcon(passwordCriteria.length)}
                    At least 12 characters
                  </li>
                </ul>
              </div>
            )}
            {errorMessage && (
              <div className="text-red-500 text-center text-sm">{errorMessage}</div>
            )}
            <button
              type="submit"
              className="bg-[#fabf01] text-[#272727] px-3 py-2 font-medium rounded-lg shadow-lg"
            >
              Next
            </button>
          </form>
        ) : (
          <form className="flex flex-col space-y-4" onSubmit={handleSignUp}>
            <div className="space-y-2">
              <input
                type="text"
                id="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
                placeholder="Your Company Name"
                className="py-2 px-4 border border-[#d9d9d9] rounded-lg placeholder-[#948F8F] w-full"
              />
              <p className="text-sm text-gray-500">
                Your URL will be: {formData.subDomain}.kim.cc
              </p>
            </div>
            {errorMessage && (
              <div className="text-red-500 text-center text-sm">{errorMessage}</div>
            )}
            <div className="flex flex-col space-y-4">
              <button
                type="submit"
                disabled={isSigningUp}
                className="w-full bg-[#fabf01] text-[#272727] px-3 py-2 font-medium rounded-lg shadow-sm flex items-center justify-center"
              >
                {isSigningUp ? (
                  <>
                    <div className="loader mr-2 w-4 h-4 border-2 border-white border-t-[#085454] rounded-full animate-spin"></div>
                    Launching your workspace..
                  </>
                ) : (
                  'Launch my workspace'
                )}
              </button>
            </div>
          </form>
        )}

          <p className="text-gray-600 sm:text-sm text-center mt-6">
            Already have an account?{' '}
            <Link to={`/login${location.search}`} className="text-[#085454] font-medium underline underline-offset-4">
              Login
            </Link>
          </p>
        </div>
      </div>
  );
}

export default Signup;
