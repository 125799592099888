import React, { useState } from 'react';
import axiosInstance from '../client/axios.js';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  BoxArrowRight,
  House,
  Link45deg,
  PersonCircle,
} from 'react-bootstrap-icons';

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation(); // Line 6
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Line 7

  const logout = async () => {
    console.log('logout');
    await axiosInstance.get('/account/logout');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  return (
    <aside className="w-[15%] bg-[#EBEFFF] shadow-md p-4 flex flex-col h-full overflow-y-auto">
      <nav className="mt-6 space-y-2 flex-grow">
        <Link
          to="/"
          className={`flex items-center py-2 px-4 rounded ${
            location.pathname === '/' || location.pathname === '/home'
              ? 'bg-white text-black'
              : 'text-gray-600'
          }`}
        >
          <span className="mr-2">
            <House className="text-xl" />
          </span>{' '}
          Home
        </Link>
        <Link
          to="/integrations"
          className={`flex items-center py-2 px-4 rounded ${
            location.pathname === '/integrations'
              ? 'bg-white text-black'
              : 'text-gray-600'
          }`}
        >
          <span className="mr-2">
            <Link45deg className="text-xl" />
          </span>{' '}
          Integration
        </Link>
      </nav>
      <div className="flex mt-auto relative items-center gap-1">
        <PersonCircle
          className="text-2xl cursor-pointer"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        />
        {isDropdownOpen && ( // Line 39
          <div className="absolute right-0 bottom-9 mt-2 w-auto min-w-full bg-white rounded-lg shadow-lg">
            <button
              className="block w-full text-left px-4 py-2 rounded-lg text-gray-600 hover:bg-gray-100"
              onClick={logout} // Line 43
            >
              <BoxArrowRight className="mr-2" />
              Logout
            </button>
          </div>
        )}
        <span className="text-base text-highlightColor font-normal">
          {localStorage.getItem('user') &&
            JSON.parse(localStorage.getItem('user')).name}
        </span>
      </div>
    </aside>
  );
}

export default Sidebar;
