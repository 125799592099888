import axios from 'axios';

const baseURL =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_PRODUCTION_API_URL
    : `${process.env.REACT_APP_DEV_API_URL}`;

const axiosInstance = axios.create({
  baseURL,
  timeout: 60000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // You can add any common request configurations here
    // For example, adding an auth token
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    response,
  (error) =>
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    Promise.reject(error)
);

export default axiosInstance;
