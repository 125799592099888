import GorgiasLogo from '../assets/gorgias-logo.png';
export const INTEGRATION_LIST = [
  {
    name: 'shopify',
    logo: 'https://cdn.worldvectorlogo.com/logos/shopify.svg',
    isConnected: false,
    requiredFields: [{ displayName: 'Shop Name', name: 'shop', value: '' }],
  },
  {
    name: 'notion',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/4/45/Notion_app_logo.png',
    isConnected: false,
    requiredFields: [
      { displayName: 'Integration Token', name: 'integrationToken', value: '' },
    ],
  },
  {
    name: 'gorgias',
    logo: GorgiasLogo,
    isConnected: false,
    requiredFields: [{ displayName: 'Shop Name', name: 'shop', value: '' }],
  },
];
