import React, { useState, useEffect } from 'react';
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import Frame from './assets/Frame.png';
import KimLogo from './assets/kim-logo-transparent.png';
import axiosInstance from './client/axios.js';

function Signup() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const redirectPlatform = searchParams.get('redirectPlatform');

  const [formData, setFormData] = useState({
    fullName: '',
    companyName: '',
    subDomain: '',
    email: '',
    password: '',
  });
  const [passwordCriteria, setPasswordCriteria] = useState({
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
    length: false,
  });
  const [showPasswordCriteria, setShowPasswordCriteria] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSigningUp, setIsSigningUp] = useState(false);

  useEffect(() => {
    const subdomainName = formData.companyName
      .split(' ')
      .join('-')
      .toLowerCase();
    setFormData((prev) => ({ ...prev, subDomain: subdomainName }));
  }, [formData.companyName]);

  const validatePassword = (password) => {
    setShowPasswordCriteria(true);
    setPasswordCriteria({
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      length: password.length >= 12,
    });
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
    if (id === 'password') {
      validatePassword(value);
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    const allValid = Object.values(passwordCriteria).every(Boolean);
    if (!allValid) {
      setErrorMessage('Please meet all password criteria.');
      return;
    }
    setIsSigningUp(true);
    setErrorMessage('');

    try {
      const response = await axiosInstance.post(
        `/account${location.search}`,
        formData,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      console.log(response, 'response', process.env.AWS_HOSTED_ZONE_DOMAIN);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      console.log(response.data.shopifyRedirectURL, 'shopifyRedirectURL');
      if (response.data.shopifyRedirectURL) {
        window.open(response.data.shopifyRedirectURL, '_self');
        return;
      }

      if (redirectPlatform === 'crm') {
        if (process.env.REACT_APP_ENVIRONMENT == 'development') {
          window.location.href = `https://feature.kim.cc/app/login`;
        } else {
          const intervalId = setInterval(async () => {
            try {
              const response = await axiosInstance.get(
                `/domain-check?subdomain=${formData.subDomain}`
              );
              if (response.data.data_services === 'ok') {
                clearInterval(intervalId);
                const ssoResponse = await axiosInstance.get('/account/sso');
                const url = new URL(ssoResponse.data.ssoLink.url);
                const params = new URLSearchParams(url.search);
                const sso_auth_token = params.get('sso_auth_token');
                window.location.href = `https://${formData.subDomain}.kim.cc/app/login?email=${formData.email}&sso_auth_token=${sso_auth_token}`;
              }
            } catch (error) {
              console.error('Error:', error);
            }
          }, 5000);
        }
      } else {
        navigate(`/home`);
      }
    } catch (error) {
      console.error('Error:', error);
      const errorMessage =
        error?.response?.data && error?.response?.data?.message
          ? error?.response?.data?.message
          : 'Failed to create account. Please try again.';
      setErrorMessage(errorMessage);
      setIsSigningUp(false);
    }
  };

  const renderCriteriaIcon = (isValid) => {
    return isValid ? (
      <span className="text-green-500 mr-2">✓</span>
    ) : (
      <span className="text-red-500 mr-2">✗</span>
    );
  };

  return (
    <div
      style={{ backgroundImage: `url(${Frame})` }}
      className="flex justify-center items-center h-screen bg-cover bg-center"
    >
      <div className="form p-8 rounded-lg max-w-sm w-full bg-white shadow-lg">
        <img src={KimLogo} alt="Kim Logo" className="w-1/3 mx-auto mb-4" />
        <h2
          className="text-2xl font-bold mb-6 text-center"
          style={{ color: '#444444' }}
        >
          Create Your Account
        </h2>

        <form className="flex flex-col space-y-4" onSubmit={handleSignUp}>
          <input
            type="text"
            id="fullName"
            value={formData.fullName}
            onChange={handleInputChange}
            placeholder="Full Name"
            className="p-2 border border-[#272727] rounded-lg placeholder-[#948F8F]"
          />
          <input
            type="text"
            id="companyName"
            value={formData.companyName}
            onChange={handleInputChange}
            placeholder="Company Name"
            className="p-2 border border-[#272727] rounded-lg placeholder-[#948F8F]"
          />
          <div className="flex items-center">
            <input
              type="text"
              id="subDomain"
              value={formData.subDomain}
              onChange={handleInputChange}
              placeholder="Subdomain"
              className="p-2 border border-[#272727] border-r-0 rounded-l-lg flex-grow placeholder-[#948F8F]"
            />
            <span className="p-2 bg-disableColor border border-l-0 border-[#272727] rounded-r-lg">
              .kim.cc
            </span>
          </div>
          <input
            type="text"
            id="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Your Email"
            className="p-2 border border-[#272727] rounded-lg placeholder-[#948F8F]"
            autoComplete="off"
          />
          <input
            type="password"
            id="password"
            value={formData.password}
            onChange={handleInputChange}
            placeholder="Password"
            className="p-2 border border-[#272727] rounded-lg placeholder-[#948F8F]"
            autoComplete="off"
          />
          {showPasswordCriteria && (
            <div className="text-sm">
              <ul className="grid grid-cols-2 gap-2">
                <li
                  className={
                    passwordCriteria.uppercase
                      ? 'text-green-500'
                      : 'text-red-500'
                  }
                >
                  {renderCriteriaIcon(passwordCriteria.uppercase)}
                  An uppercase letter
                </li>
                <li
                  className={
                    passwordCriteria.lowercase
                      ? 'text-green-500'
                      : 'text-red-500'
                  }
                >
                  {renderCriteriaIcon(passwordCriteria.lowercase)}A lowercase
                  letter
                </li>
                <li
                  className={
                    passwordCriteria.number ? 'text-green-500' : 'text-red-500'
                  }
                >
                  {renderCriteriaIcon(passwordCriteria.number)}A number
                </li>
                <li
                  className={
                    passwordCriteria.special ? 'text-green-500' : 'text-red-500'
                  }
                >
                  {renderCriteriaIcon(passwordCriteria.special)}A special
                  character
                </li>
                <li
                  className={
                    passwordCriteria.length ? 'text-green-500' : 'text-red-500'
                  }
                >
                  {renderCriteriaIcon(passwordCriteria.length)}
                  At least 12 characters
                </li>
              </ul>
            </div>
          )}
          {errorMessage && (
            <div className="text-red-500 text-center">{errorMessage}</div>
          )}
          <button
            type="submit"
            disabled={isSigningUp}
            className="bg-[#fabf01] text-[#085454] p-3 font-bold rounded-lg shadow-lg flex items-center justify-center"
          >
            {isSigningUp ? (
              <>
                <div className="loader mr-2 w-5 h-5 border-2 border-white border-t-[#085454] rounded-full animate-spin"></div>
                Signing Up...
              </>
            ) : (
              'Sign Up'
            )}
          </button>
        </form>
        <div className="text-center mt-4">
          Already have an account?{' '}
          <Link to={`/login${location.search}`} className="text-[#085454]">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Signup;
