import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GorgiasLogo from './assets/gorgias-logo.png';

const Gorgias = () => {
  const [isChecked, setIsChecked] = useState(true);
  const [accountName, setAccountName] = useState('');
  const [isGorgiasConnected, setGorgiasConnected] = useState(false);
  const [showError, setShowError] = useState('');

  const navigate = useNavigate();
  const gorgiasDevServerUrl = process.env.REACT_APP_GORGIAS_DEV_SERVER_URL;

  const fetchDetail = async (account, intervalId) => {
    const resp = await fetch(
      gorgiasDevServerUrl + `/user/fetchProfile?account=${account}`,
      {
        headers: {
          'ngrok-skip-browser-warning': 'true',
        },
      }
    );
    const responseData = await resp.json();
    if (responseData) {
      const { accessToken, refreshToken } = responseData;
      localStorage.setItem('gat', accessToken);
      localStorage.setItem('grt', refreshToken);
      localStorage.setItem('an', account);
      setAccountName(account);
      setGorgiasConnected(true);
      clearInterval(intervalId);
    } else {
      setShowError(true);
    }
  };

  const connectWithGorgias = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    window.open(
      `http://localhost:3005/api/gorgias/oauth/install?account=${accountName}&id=${user.id}`,
      'mozillaWindow',
      'width=500, height=500'
    );

    const intervalId = setInterval(() => {
      fetchDetail(accountName, intervalId);
    }, 5000);

    setTimeout(() => {
      clearTimeout(intervalId);
    }, 300000);
  };

  const handleInputChange = (event) => {
    setAccountName(event.target.value);
  };

  const handleCheckboxChange = (value) => {
    setIsChecked(value);
  };

  useEffect(() => {
    if (isGorgiasConnected) {
      navigate('/connect/success');
    }
  }, [isGorgiasConnected]);
  return (
    <div className="bg-[#E8EAF6] w-full h-full flex flex-col justify-center items-center">
      <div className="bg-white mx-auto rounded-2xl">
        <div className="grid grid-cols-2 gap-16 justify-center items-center px-16 py-10">
          <img src={GorgiasLogo} className="max-w-[20rem]" />
          <div>
            <div className="flex flex-col">
              <div className="flex flex-col">
                <div className="mb-2 text-sm text-[#444746]">
                  Please enter your gorgias subdomain
                </div>
                <div className="flex items-center">
                  <div className="flex rounded-lg ring-1 ring-slate-300 bg-white mt-1">
                    <input
                      type="text"
                      placeholder="your account name"
                      className="rounded-lg p-4 w-full outline-none text-base"
                      onChange={handleInputChange}
                      value={accountName}
                    ></input>
                  </div>
                  <span className="text-sm text-[#444746] pl-2">
                    .gorgias.com
                  </span>
                </div>
              </div>

              <div className="mt-6">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="termsCheckbox"
                    checked={isChecked}
                    onChange={(e) => handleCheckboxChange(e.target.checked)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="termsCheckbox"
                    className="ml-2 text-sm text-gray-700"
                  >
                    I agree to the terms and conditions
                  </label>
                </div>
              </div>
            </div>

            <button
              onClick={connectWithGorgias}
              className={`w-full text-white rounded-lg bg-[#181636] px-10 py-5 mt-10 ${
                accountName.length === 0 || !isChecked
                  ? 'disabled:bg-gray-400 cursor-not-allowed'
                  : ''
              }`}
              disabled={accountName.length === 0 || !isChecked}
            >
              Connect
            </button>
            {showError ? (
              <div className="mt-2 text-red-500">
                Something went wrong. Please try again
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gorgias;
